<template>
  <Containers class="w-full" :status="status" @funEnd='funEnd' :active='4' >

    <div class="w" v-if="detail.objectId">
      <!-- 标题开始 -->
      <div class="detail-title w flex justify-center items-center">
        <headlines class="headlines live-XQ" :title='detail.name' english="STORE DETAILS" color="#fff" ></headlines>
      </div>
      <!-- 标题结束 -->

      <!-- 招牌产品开始 -->
      <div class="detail w flex flex-col justify-center items-center" >
        <div class="detail-img wow fadeIn" :style="{backgroundImage: 'url(' + detail.picture + ')','background-repeat': 'no-repeat','backgroundSize': 'cover','background-position': 'center center'}"></div>
        <div class="small-w text-blue " >
          <div class="specification wow fadeInUp">
            <iconFont class="icon" icon='type' color='#CA4938' :size='20' ></iconFont>
            {{detail.type}}
          </div>
          <div class="specification wow fadeInUp">
            <iconFont class="icon" icon='address1' color='#CA4938' :size='20' ></iconFont>
            {{detail.address}}
          </div>
          <div class="specification wow fadeInUp">
            <iconFont class="icon" icon='time' color='#CA4938' :size='20' ></iconFont>
            {{detail.businessTime.start}}-{{detail.businessTime.end}}
          </div>
          <div class="specification wow fadeInUp">
            <iconFont class="icon" icon='iphone' color='#CA4938' :size='20' ></iconFont>
            {{detail.phone}}
          </div>
        </div>
        <div class="wire small-w"></div>
        <div class="detail-back small-w flex text-red items-center cursor-pointer pc-show">
          <div class="icon-more">
            <iconFont class="icon" icon='leftarrow' color='#CA4938' :size='18' ></iconFont>
          </div>
          <div class="ml-20" @click="$back()">返回门店列表</div>
        </div>
        <a-row class="small-w shops-container pb-110" :gutter="[24,24]">
          <a-col :xs="12" :sm="12" :md="6"  class="shops-container-item text-blue m-0 cursor-pointer" v-for="(item,index) in allShop" :key="index">
            <div class="shop-name ell wow fadeInUp" @click="toDetail(item.objectId)">{{item.name}}</div>
          </a-col>
        </a-row>
      </div>
      <!-- 招牌产品结束 -->

    </div>
  </Containers>
</template>

<script>
import headlines from '@/components/headlines'

import iconFont from '@/components/iconFont'
import { WOW } from 'wowjs'

export default {
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'keywords',
          content: '双美豆沙牛乳门店分布详情_双美豆沙牛乳官网'
        },
        {
          name: 'description',
          content: '本页面为您提供双美豆沙牛乳门店分布详情，包含双美豆沙牛乳旗下各种类型门店等相关信息'
        }
      ]
    }
  },
  data () {
    return {
      status: 'success',
      detail: {},
      allShop: [],
      title: '',
      text: '', // 压缩的文本
    }
  },
  updated () {
    this.$nextTick(() => {
      const wow = new WOW({
        live: false
      })
      wow.init()
    })
  },
  mounted () {
    this.getInit()
  },
  components: {
    headlines,
    iconFont,
  },
  computed: {
  },
  methods: {
    funEnd () {
      // setTimeout(() => {
      //   this.status = 'success'
      //   this.$nextTick(() => {
      //     // 在dom渲染完后,再执行动画
      //     const wow = new WOW({
      //       live: false
      //     })
      //     wow.init()
      //   })
      // }, 300)
    },
    async getInit () {
      try {
        setTimeout(async () => {
          // this.status = 'waiting'
          this.text = ''
          await this.getDetail()
          await this.getAllShopName()
          await this.getFont(this.text)
          // this.status = 'requestEnd'
        }, 0)
      } catch (e) {
        this.status = 'error'
      }
      this.$nextTick(() => {
        // 在dom渲染完后,再执行动画
        const wow = new WOW({
          live: false
        })
        wow.init()
      })
    },
    async getDetail () {
      try {
        let detail = await this.$axios.get(`/classes/shopList/${this.$route.query.id}`)
        this.detail = detail
        this.title = detail.name
        this.text = detail.name
      } catch (e) {
        console.log(e)
      }
    },
    async getAllShopName () {
      let list = await this.$axios.get('/classes/shopList', {
        params: {
          order: '-createdAt',
          keys: 'objectId,name',
          limit: 1000,
          where: {
            objectId: {
              $ne: this.$route.query.id
            }
          }
        }
      })
      this.allShop = list.results
    },
    toDetail (id) {
      this.$replace({
        path: '/shop-detail',
        query: {
          id: id
        }
      })
      this.getInit()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/mixin1700.scss';

@mixin smalls {
  @media screen and (max-width: 900px) {
    @content;
  }
}

@mixin img {
  @media screen and (max-width: 1050px) {
    @content
  }
}

.small-w{
  width: 800px;
  @include smalls {
    width: 100% !important;
  }
}
.detail-title{
  padding: 210px 0 299px;
  @include hamburger {
    padding: 70px 30px 80px;
  }
}
.detail{
  background-color: #FCFAF0;
  @include hamburger {
    padding: 0 12px;
  }
  .detail-img{
    width: 1028px;
    height: 576px;
    margin: 0 auto;
    margin-top: -249px;
    margin-bottom: 40px;
    @include img {
      width: 100%;
    }
    @include hamburger {
      width: 100%;
      height: 179px;
      margin-top: -68px;
      margin-bottom: 24px;
    }
  }
  .wire{
    border-top: 1px solid rgba(71, 102, 133, 0.1);
    margin-top: 80px;
    @include hamburger {
      margin: 24px 0;
    }

  }
  .detail-back{
    padding: 50px 0 80px;
  }
}
.icon-more{
  width: 20px;
  height: 20px;
  border: 1.5px solid #CA4938;
  .icon{
    left: unset;
    right: -8px;
  }
}

.shop-name{
  position: relative;
  padding-left: 20px;
  &::before{
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    /* 2 */
    border: 2px solid #CA4938;
    border-radius: 50%;
    box-sizing: border-box
  }
}
.specification{
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  .icon{
    margin-right: 9px;
  }
}
</style>
